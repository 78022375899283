/* Header Stylesheet */

@font-face {
    font-family: 'Verona';
    src: local('Verona'), url(../fonts/Verona-Serial-Regular.ttf) format('truetype');
  }

  @font-face {
    font-family: 'VeronaBold';
    src: local('VeronaBold'), url(../fonts/verona-serial-bold.ttf) format('truetype');
  }

body {
    color:#1F4248;
    font-family: 'Verona', sans-serif;

}
 
html {
    scroll-behavior: smooth
}

.header {
    background-color: #f4cdc4;
    border-radius: 0;
}

.heading {
    color: #1F4248;
    font-weight: 600;
    font-family: 'Jost', sans-serif;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}

.heading p {
    font-size: 18px;
    background-color: #f1b0a0;
    -moz-box-shadow: 15px 10px #fbe5e0;
    box-shadow: 15px 10px #fbe5e0;
    -webkit-box-shadow: 15px 10px #fbe5e0;
    margin-right: 3rem;
    margin-left: 3rem;
}

.header-image {
    margin-top: 1rem;
}

.about {
    padding-bottom: 3rem;
}

.launch {
    text-align: center;
    font-family: VeronaBold;
    font-size: 20px;
}



.signup-form {
background-color:transparent;
}

.signup-form .form-control {
    color: #1F4248;
    background-color:#edc3b0 ;
    border: 2px solid #1F4248;
}

.signup-form .form-control::placeholder {
    color: #1F4248;
}

.signup-form label {
    font-weight: 500;
}

.signup-form .beta {
    margin: auto;
}

.signup-form .btn {
    background-color: #1F4248;
    border: none;
    padding: 0.5rem 3rem;
    border-radius: 0;
}

.signup-form .submitted {
    text-align: center;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
     -ms-animation: fadein 2s; /* Internet Explorer */
      -o-animation: fadein 2s; /* Opera < 12.1 */
         animation: fadein 2s;
   text-align: center;
   font-family: VeronaBold;
   font-size: 18px;
}

.afterSubmit {

-webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;

}

.signup-form .btn:hover {
    color: #edc3b0;
    background-color: #1F4248;
}

.signup-form .form-check {
    margin-bottom: 1rem;
}

.signup-form .get-on-the-list {
    text-align: center;
}

@media only screen and (max-width: 600px) {
   .open {
       margin-left: 0 !important;
       text-align: center;
   }

   .test {
    background-position: 82% 153px !important;

   }

   .logo {
       height: 300px;
   }

}

@media only screen and (min-width: 401px) and (max-width: 500px) {
    .open {
        margin-left: 0 !important;
        text-align: center;
    }
 
    .test {
     background-position: 74% 151px !important;
 
    }
 
    .logo {
        height: 300px;
    }
 
 }

 @media only screen and (max-width: 400px) {
    .open {
        margin-left: 0 !important;
        text-align: center;
    }
 
    .test {
     background-position: 72% 154px !important;
 
    }
 
    .logo {
        height: 300px;
    }
 
 }


@media only screen and (min-width: 601px) and (max-width: 739px) {
    .open {
        margin-left: 0 !important;
        text-align: center;
    }
 
    .test {
     background-position:right -429px bottom -100px !important;
 
    }

 
 }

 @media only screen and (min-width: 740px) and (max-width: 1025px) {
    .open {
        margin-left: 0 !important;
        text-align: center;
    }
 
    .test {
     background-position:right -429px bottom -100px !important;
 
    }

    .col-md-5 {
        flex: 0 0 70.666667% !important;
        max-width: 70.666667% !important;
    }
 
 }

 @media only screen and (min-width: 601px) and (max-width: 1025px) and (orientation: landscape) {
    .open {
        margin-left: 0 !important;
        text-align: center;
    }
 
    .test {
     background-position:center !important;
 
    }
 
 }
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.test2 {
    background: #104349 url('../images/overlay_pleaseme.png');
    min-height: 100vh;
    min-width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;

}

.test {
    background: #edc3b0 url('../images/pleaseme-main.png');
    min-height: 100vh;
    min-width: 100%;
     background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
     -ms-animation: fadein 2s; /* Internet Explorer */
      -o-animation: fadein 2s; /* Opera < 12.1 */
         animation: fadein 2s;

}

.open {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
body {
    margin: 0;
    background: #fbe5e0;
    font-family: 'Jost', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html, body {
    padding: 0! important;
    overflow-x:hidden !important;
    padding: env(safe-area-inset) !important;

}